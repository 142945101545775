<template>
  <main>
    <div id="printContainer" class="main-grid">
      <div class="aside">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ name: 'home' }">
            <font-awesome-icon :icon="[ 'fas', 'home' ]" />
          </el-breadcrumb-item>
          <el-breadcrumb-item
            :to="lastPageLinkTo"
            v-if="lastPageLinkTo">
            查詢結果
          </el-breadcrumb-item>
          <el-breadcrumb-item>
            {{companyName}}
          </el-breadcrumb-item>
        </el-breadcrumb>
        <section
          v-loading="isCompanyDataLoading">
          <CompanySummaryAside
            :basic="companyData.basic">
          </CompanySummaryAside>
        </section>
        <section>
          <RecentQuery>
          </RecentQuery>
        </section>
      </div>
      <div
        class="container">
        <!-- <CompanyDetailCard
          v-if="isCompanyDataLoading==false"
          :basicData="basicData"
          :riskData="riskData"
          :operatingData="operatingData"
          :financeData="financeData"
          :companyNews="companyNews">
        </CompanyDetailCard> -->
        <el-tabs v-model="currentTabName">
          <el-tab-pane
            label="基本資料"
            name="basic"
            v-if="isShowBasicTab">
            <div
              class="company-container"
              v-loading="isCompanyDataLoading">
              <section>
                <!-- <h4 class="section-title">公司簡介</h4>
                <pre style="white-space:pre-wrap">{{companyDescription}}</pre> -->
                <CompanyDescriptionSection
                  :description="companyDescription">
                </CompanyDescriptionSection>
              </section>
              <section
                v-if="companyData.basic">
                <CompanyBasicTable
                  :basic="companyData.basic">
                </CompanyBasicTable>
              </section>
              <section
                v-if="companyData.shareholders">
                <ShareholdersTable
                  :shareholders="companyData.shareholders"
                  :uniID="currentUniID"
                  :stockType="getterStockType">
                </ShareholdersTable>
              </section>
              <section
                v-if="companyData.managers">
                <ManagersTable
                  :managers="companyData.managers"
                  :uniID="currentUniID">
                </ManagersTable>
              </section>
              <section
                v-if="companyData.companys_history">
                <CompanysHistoryTable
                  :companys_history="companyData.companys_history"
                  :uniID="currentUniID">
                </CompanysHistoryTable>
              </section>
              <section
                v-if="companyData.company_branchs">
                <CompanyBranchsTable
                  :company_branchs="companyData.company_branchs"
                  :uniID="currentUniID">
                </CompanyBranchsTable>
              </section>
              <section
                v-if="companyData.factorys">
                <FactorysTable
                  :factorys="companyData.factorys"
                  :uniID="currentUniID">
                </FactorysTable>
              </section>
              <section
                v-if="companyData.登記地址近似公司">
                <SimilarAddressTable
                  :登記地址近似公司="companyData.登記地址近似公司"
                  :uniID="currentUniID">
                </SimilarAddressTable>
              </section>
            </div>
          </el-tab-pane>
          <el-tab-pane
            label="新聞事件"
            name="news"
            v-if="isShowNewsTab">
            <div
              class="company-container"
              v-loading="isCompanyNewsLoading">
              <section
                v-loading="!companyNews">
                <!-- <NewsTable
                  :news="companyNews"
                  :uniID="currentUniID">
                </NewsTable> -->
                <NewsList
                  :news="companyNews"
                  :uniID="currentUniID"
                  :companyName="companyName">
                </NewsList>
              </section>
            </div>
          </el-tab-pane>
          <el-tab-pane
            label="法律訴訟"
            name="verdicts"
            v-if="isShowVerdictsTab">
            <div
              class="company-container"
              v-loading="isCompanyDataLoading">
              <section
                v-if="companyData.verdicts">
                <VerdictsList
                  :verdicts="companyData.verdicts"
                  :basic="companyData.basic"
                  :uniID="currentUniID">
                </VerdictsList>
              </section>
            </div>
          </el-tab-pane>
          <el-tab-pane
            label="關係人地圖"
            name="stock"
            v-if="isShowStockTab">
            <div
              class="company-container"
              v-if="currentTabName=='stock'&&currentUniID"
              v-loading="isCompanyDataLoading">
              <section>
                <h4 class="section-title">
                  股權結構分析圖
                  <el-button
                    type="primary"
                    size="mini"
                    style="margin-left:10px"
                    v-if="chartVersion==1"
                    @click="handleChartVersionChange">
                    體驗新版
                  </el-button>
                  <el-button
                    plain
                    size="mini"
                    style="margin-left:10px"
                    v-else-if="chartVersion==2"
                    @click="handleChartVersionChange">
                    恢復舊版
                  </el-button>
                </h4>
                <!-- <CompanyStockRelationshipChart
                  v-loading="isStockLoading"
                  :uniID="currentUniID"
                  :companyDataList="companyStockList"
                  :companyName="companyName">
                </CompanyStockRelationshipChart> -->
                <CompanyStockRelationshipChart
                  v-if="chartVersion==1"
                  v-loading="isGoFullLoading"
                  :uniID="currentUniID"
                  :sourceData="companyGoFull"
                  :companyName="companyName">
                </CompanyStockRelationshipChart>
                <CompanyStockTree2Chart
                  v-else-if="chartVersion==2"
                  v-loading="isGoFullLoading"
                  :uniID="currentUniID"
                  :chartData="companyGoFull"
                  :companyName="companyName">
                </CompanyStockTree2Chart>
              </section>
              <section>
                <h4 class="section-title">
                  企業關聯圖譜
                </h4>
                <!-- <CompanyStockDirectedChart
                  v-loading="isStockLoading"
                  :uniID="currentUniID"
                  :companyDataList="companyStockList"
                  :companyName="companyName">
                </CompanyStockDirectedChart> -->
                <CompanyStockDirectedChart
                  v-loading="isGoFullLoading"
                  :uniID="currentUniID"
                  :sourceData="companyGoOld"
                  :companyName="companyName">
                </CompanyStockDirectedChart>
              </section>
            </div>
          </el-tab-pane>
          <el-tab-pane
            label="風險指標"
            name="risk"
            v-if="isShowRiskTab">
            <div
              class="company-container"
              v-loading="isCompanyDataLoading">
              <!-- <section
                v-if="companyData.verdicts">
                <VerdictsTable
                  :verdicts="companyData.verdicts"
                  :basic="companyData.basic"
                  :uniID="currentUniID">
                </VerdictsTable>
              </section> -->
              <section
                v-if="companyData.disputes">
                <DisputesTable
                  :disputes="companyData.disputes"
                  :uniID="currentUniID">
                </DisputesTable>
              </section>
              <section
                v-if="companyData.procurement_bad_vendors">
                <ProcurementBadVendorsTable
                  :procurement_bad_vendors="companyData.procurement_bad_vendors"
                  :uniID="currentUniID">
                </ProcurementBadVendorsTable>
              </section>
              <section
                v-if="companyData.data_gov_decision">
                <DataGovDecisionTable
                  :data_gov_decision="companyData.data_gov_decision"
                  :uniID="currentUniID">
                </DataGovDecisionTable>
              </section>
              <section
                v-if="companyData.data_gov_environmental_punishment">
                <DataGovEnvironmentalPunishmentTable
                  :data_gov_environmental_punishment="companyData.data_gov_environmental_punishment"
                  :uniID="currentUniID">
                </DataGovEnvironmentalPunishmentTable>
              </section>
              <section
                v-if="companyData.epa">
                <EpaTable
                  :epa="companyData.epa"
                  :uniID="currentUniID">
                </EpaTable>
              </section>
            </div>
          </el-tab-pane>
          <el-tab-pane
            label="經營指標"
            name="operating"
            v-if="isShowOperatingTab">
            <div
              class="company-container"
              v-loading="isCompanyDataLoading">
              <section
                v-if="companyData.trades">
                <TradesTable
                  :basic="companyData.basic"
                  :trades="companyData.trades"
                  :uniID="currentUniID">
                </TradesTable>
              </section>
              <section
                v-if="companyData.procurements_award">
                <ProcurementsTable
                  :basic="companyData.basic"
                  :procurements_award="companyData.procurements_award"
                  :uniID="currentUniID">
                </ProcurementsTable>
              </section>
              <section
                v-if="companyData.job104s_jobs">
                <JobsTable
                  :basic="companyData.basic"
                  :job104s_jobs="companyData.job104s_jobs"
                  :uniID="currentUniID">
                </JobsTable>
              </section>
              <section
                v-if="companyData.patent_rights">
                <PatentsTable
                  :patent_rights="companyData.patent_rights"
                  :uniID="currentUniID">
                </PatentsTable>
              </section>
              <section
                v-if="companyData.awards">
                <AwardsTable
                  :awards="companyData.awards"
                  :uniID="currentUniID">
                </AwardsTable>
              </section>
            </div>
          </el-tab-pane>
          <el-tab-pane
            label="財務指標"
            name="finance"
            v-if="isShowFinanceTab">
            <div
              class="company-container"
              v-loading="isCompanyDataLoading">
              <section
                v-if="companyData.publics_news">
                <PublicsNewsTable
                  :basic="companyData.basic"
                  :publics_news="companyData.publics_news"
                  :uniID="currentUniID">
                </PublicsNewsTable>
              </section>
              <section
                v-if="companyData.revenue_history">
                <RevenueHistoryTable
                  :basic="companyData.basic"
                  :revenue_history="companyData.revenue_history"
                  :uniID="currentUniID">
                </RevenueHistoryTable>
              </section>
              <section
                v-if="companyData.ifrss_summary">
                <IfrssSummaryTable
                  :ifrss_summary="companyData.ifrss_summary"
                  :uniID="currentUniID">
                </IfrssSummaryTable>
              </section>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <MainPageFloatButton
      ref="elFloatButton"
      :downloadAction="openDownloadSelector">
    </MainPageFloatButton>
    <!-- <TheDownloadContent
      v-if="downloadCompanyData!=null"
      :companyData="downloadCompanyData">
    </TheDownloadContent> -->
    <!-- 下載pdf時才顯示（實際上並不真正顯示給使用者看，只是截圖用而已） -->
    <CompanyDetailDownloader
      :active.sync="isActiveCompanyDetailDownloader"
      :company="downloadCompanyData"
      :condition="downloadCondition"
      :downloadRequestFilter="downloadRequestFilter"
      >
    </CompanyDetailDownloader>
    <!-- <CompanyStockIntroLightbox
      :visible="isIntroLightboxVisible">
    </CompanyStockIntroLightbox> -->
    <CompanyDownloadSelector
      :visible.sync="isDownloadSelectorVisible"
      :uniID="currentUniID"
      :tabs="{
        basic: isShowBasicTab,
        news: isShowNewsTab,
        verdicts:isShowVerdictsTab,
        risk: isShowRiskTab,
        operating: isShowOperatingTab,
        finance: isShowFinanceTab
      }"
      @download="downloadAction"
    />

  </main>
</template>

<script>
import routerService from '@/utils/routerService.js'
import * as apis from '@/apis/index.ts'
import * as apiInterfaces from '@/types/apis'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import * as screenImageGetter from '@/utils/screenImageGetter.ts'
import getCompanyStockList from '@/utils/getCompanyStockList_legacy.js'
import isCompanyClosed from '@/utils/isCompanyClosed.js'
// import downloadCompanyDetail from '@/utils/downloadCompanyDetail.ts'
import CompanySummaryAside from '@/components/CompanySummaryAside/CompanySummaryAside.vue'
// import CompanyDetailCard from '@/components/CompanyDetailCard/CompanyDetailCard.vue'
import RecentQuery from '@/components/RecentQuery/RecentQuery.vue'
import CompanyDescriptionSection from '@/components/CompanyDescriptionSection/CompanyDescriptionSection.vue'
import CompanyBasicTable from '@/components/CompanyBasicTable/CompanyBasicTable.vue'
import ShareholdersTable from '@/components/ShareholdersTable/ShareholdersTable.vue'
import ManagersTable from '@/components/ManagersTable/ManagersTable.vue'
import CompanysHistoryTable from '@/components/CompanysHistoryTable/CompanysHistoryTable.vue'
import CompanyBranchsTable from '@/components/CompanyBranchsTable/CompanyBranchsTable.vue'
import FactorysTable from '@/components/FactorysTable/FactorysTable.vue'
import SimilarAddressTable from '@/components/SimilarAddressTable/SimilarAddressTable.vue'
// import VerdictsTable from '@/components/VerdictsTable/VerdictsTable.vue'
import VerdictsList from '@/components/VerdictsList/VerdictsList.vue'
// import NewsTable from '@/components/NewsTable/NewsTable.vue'
import NewsList from '@/components/NewsList/NewsList.vue'
import DisputesTable from '@/components/DisputesTable/DisputesTable.vue'
import ProcurementBadVendorsTable from '@/components/ProcurementBadVendorsTable/ProcurementBadVendorsTable.vue'
import DataGovDecisionTable from '@/components/DataGovDecisionTable/DataGovDecisionTable.vue'
import DataGovEnvironmentalPunishmentTable from '@/components/DataGovEnvironmentalPunishmentTable/DataGovEnvironmentalPunishmentTable.vue'
import EpaTable from '@/components/EpaTable/EpaTable.vue'
import TradesTable from '@/components/TradesTable/TradesTable.vue'
import ProcurementsTable from '@/components/ProcurementsTable/ProcurementsTable.vue'
import JobsTable from '@/components/JobsTable/JobsTable.vue'
import PatentsTable from '@/components/PatentsTable/PatentsTable.vue'
import AwardsTable from '@/components/AwardsTable/AwardsTable.vue'
import PublicsNewsTable from '@/components/PublicsNewsTable/PublicsNewsTable.vue'
import RevenueHistoryTable from '@/components/RevenueHistoryTable/RevenueHistoryTable.vue'
import IfrssSummaryTable from '@/components/IfrssSummaryTable/IfrssSummaryTable.vue'
import MainPageFloatButton from '@/components/MainPageFloatButton/MainPageFloatButton.vue'
// import TheDownloadContent from '@/components/TheDownloadContent/TheDownloadContent.vue'
// import TradesChart from '@/components/TradesChart/TradesChart.vue'
// import RevenueHistoryChart from '@/components/RevenueHistoryChart/RevenueHistoryChart.vue'
import CompanyDetailDownloader from '@/components/CompanyDetailDownloader/CompanyDetailDownloader.vue'
import CompanyDownloadSelector from '@/components/CompanyDownloadSelector/CompanyDownloadSelector.vue'
// import CompanyStockRelationshipChart from '@/components/CompanyStockRelationshipChart_legacy/CompanyStockRelationshipChart.vue'
// import CompanyStockDirectedChart from '@/components/CompanyStockDirectedChart_legacy/CompanyStockDirectedChart.vue'
import CompanyStockRelationshipChart from '@/components/charts/businessNetwork/CompanyStockRelationshipChart.vue'
import CompanyStockTree2Chart from '@/components/charts/businessNetwork/CompanyStockTree2Chart.vue'
import CompanyStockDirectedChart from '@/components/charts/businessNetwork/CompanyStockDirectedChart.vue'
// import CompanyStockIntroLightbox from '@/components/CompanyStockIntroLightbox/CompanyStockIntroLightbox.vue'
// import moment from 'moment'
// import pdfMake from 'pdfmake/build/pdfmake'
// import pdfFonts from 'pdfmake/build/vfs_fonts'
import relationshipChartVersionStorage from '@/storage/relationshipChartVersionStorage'

export default {
  components: {
    CompanySummaryAside,
    RecentQuery,
    // CompanyDetailCard
    CompanyDescriptionSection,
    CompanyBasicTable,
    ShareholdersTable,
    ManagersTable,
    CompanysHistoryTable,
    CompanyBranchsTable,
    FactorysTable,
    SimilarAddressTable,
    // VerdictsTable,
    VerdictsList,
    // NewsTable,
    NewsList,
    DisputesTable,
    ProcurementBadVendorsTable,
    DataGovDecisionTable,
    DataGovEnvironmentalPunishmentTable,
    EpaTable,
    TradesTable,
    ProcurementsTable,
    JobsTable,
    PatentsTable,
    AwardsTable,
    PublicsNewsTable,
    RevenueHistoryTable,
    IfrssSummaryTable,
    MainPageFloatButton,
    // TheDownloadContent
    // TradesChart,
    // RevenueHistoryChart
    CompanyDetailDownloader,
    CompanyDownloadSelector,
    CompanyStockRelationshipChart,
    CompanyStockDirectedChart,
    CompanyStockTree2Chart
    // CompanyStockIntroLightbox
  },
  data () {
    return {
      isCompanyDataLoading: true,
      isCompanyNewsLoading: true,
      isStockLoading: false,
      isActiveCompanyDetailDownloader: false,
      downloadCompanyData: {},
      currentTabName: 'basic',
      // companyStockList: [], // 關係人地圖資料（圖表上所有節點的公司股權資料）
      isIntroLightboxVisible: true,
      isDownloadSelectorVisible: false,
      downloadCondition:{
        basic: ["description", "basicTable", "shareholders", "managers", "history", "branch", "factory", "登記地址近似公司"],
        news:true,
        verdicts: true,
        risk: ["dispute", "badVendor"],
        operating: ["trade", "procurement", "job", "patent", "award"],
        finance:[]
      },
      downloadRequestFilter: {}, // 下載查詢條件的filter
      chartVersion: 1
    }
  },
  computed: {
    ...mapState([
      'routeHistory',
      'originRouteQuery'
    ]),
    ...mapState('companyDetail', [
      // 'basicData',
      // 'riskData',
      // 'operatingData',
      // 'financeData',
      'companyData',
      'companyNews',
      // 'downloadCompanyData'
      'isGoFullLoading',
      'companyGoFull',
      'companyGoOld'
    ]),
    ...mapGetters([
      'getterLimitD',
      'getterLimitE',
      'getterLimitF',
      'getterLimitG',
    ]),
    ...mapGetters('companyDetail', [
      'getterStockType',
      'getterIsCompanyPublic' // 為公開發行公司
    ]),
    isProduction () {
      return process.env.VUE_APP_MODE == 'build'
    },
    isShowBasicTab () {
      return this.getterLimitD
    },
    isShowNewsTab () {
      return true // @Q@ api還沒開
    },
    isShowVerdictsTab () {
      return true // @Q@ api還沒開
    },
    isShowStockTab () {
      // @Q@ 權限api還沒開
      // return this.companyData.basic && this.companyData.stock
      return true
    },
    isShowRiskTab () {
      return this.getterLimitE
    },
    isShowOperatingTab () {
      return this.getterLimitF
    },
    isShowFinanceTab () {
      return this.getterLimitG && this.getterIsCompanyPublic // 公開發行公司才顯示財務指標
    },
    // 目前的uniID
    currentUniID () {
      return this.$route.query.uniid
    },
    companyName () {
      if (this.companyData && this.companyData.basic && this.companyData.basic.company_name) {
        return this.companyData.basic.company_name
      } else {
        return ''
      }
    },
    companyDescription () {
      if (this.companyData && this.companyData.basic && this.companyData.basic.multi && this.companyData.basic.multi.description && this.companyData.basic.multi.description.length) {
        return this.companyData.basic.multi.description[0].value || '-'
      } else {
        return '-'
      }
    },
    lastPageLinkTo () {
      if (this.routeHistory.length > 1) {
        // 取得前一筆紀錄
        const lastPage = this.routeHistory[this.routeHistory.length - 2].route
        if (lastPage.name === 'companyList') {
          return {
            name: 'companyList',
            params: lastPage.params,
            query: lastPage.query
          }
        } else {
          return null
        }
      } else {
        return null
      }
    },
  },
  methods: {
    // ...mapMutations('companyDetail', [
    //   'mutationDownloadCompanyData'
    // ]),
    ...mapActions([
      'actionRiskKeywordGroup'
    ]),
    ...mapActions('companyDetail', [
      'actionCompanyNews',
      'actionCompanyDetail',
      'actionDownloadCompanyDetail',
      'actionCompanyGraphForDbs'
    ]),
    handleChartVersionChange () {
      if (this.chartVersion == 1) {
        this.chartVersion = 2
      } else {
        this.chartVersion = 1
      }
      relationshipChartVersionStorage.setVersion(this.chartVersion)
    },
    // 下載
    openDownloadSelector(){
      this.isDownloadSelectorVisible = true;
    },
    async downloadAction ({checked, filter, ignore}) {
      this.$refs.elFloatButton.showPercent(false)
      this.downloadCondition = checked
      this.downloadRequestFilter = filter

      try {
        // -- 如無權限則離開 --
        if (this.getterLimitD === false &&
        this.getterLimitE === false &&
        this.getterLimitF === false &&
        this.getterLimitG === false) {
          this.isActiveCompanyDetailDownloader = false
          return
        }
        // -- 取得資料 ---
        // const result:apiInterfaces.ICompanyGetResponse = await fetchCompanyDetail(this.currentUniID)
        // 取得所有資料（news除外）
        let searchRequest = {
          get: {},
          uniID: this.currentUniID
        }
        // 基本資料
        if (this.getterLimitD) {
          if(checked.basic.includes('shareholders')) searchRequest.get.shareholders = { 'page-size': 10000 }
          if(checked.basic.includes("managers")) searchRequest.get.managers = { 'page-size': 10000 }
          if(checked.basic.includes("history")) searchRequest.get.companys_history = { 'page-size': 10000 }
          if(checked.basic.includes("branch")) searchRequest.get.company_branchs = { 'page-size': 10000 }
          if(checked.basic.includes("factory")) searchRequest.get.factorys = { 'page-size': 10000 }
          if(checked.basic.includes('登記地址近似公司')) searchRequest.get.登記地址近似公司 = { 'page-size': 10000 }
        }
        // @Q@ 目前無相關對應權限
        if (true === true) {
          if(checked.verdicts) {
            searchRequest.get.verdicts = { 'page-size': 10000, filter: filter.verdicts.filter }
          }
          if(checked.news) searchRequest.get.news = {
            // 'remove-duplicate': 1,
            ...filter.news,
            'page-size': 500
          }
        }
        // 風險指標
        if (this.getterLimitE) {
          if(checked.risk.includes("dispute")) searchRequest.get.disputes = { 'page-size': 10000 }
          if(checked.risk.includes("badVendor")) searchRequest.get.procurement_bad_vendors = { 'page-size': 10000 }
          if(checked.risk.includes("data_gov_decision")) searchRequest.get.data_gov_decision = { 'page-size': 10000 }
          if(checked.risk.includes("data_gov_environmental_punishment")) searchRequest.get.data_gov_environmental_punishment = { 'page-size': 10000 }
          if(checked.risk.includes("epa")) searchRequest.get.epa = { 'page-size': 10000 }
        }
        // 經營指標
        if (this.getterLimitF) {
          if(checked.operating.includes("trade")) searchRequest.get.trades = { 'page-size': 10000 }
          if(checked.operating.includes("procurement")) searchRequest.get.procurements_award = { 'page-size': 10000 }
          if(checked.operating.includes("job")) searchRequest.get.job104s_jobs = { 'page-size': 10000 }
          if(checked.operating.includes("patent")) searchRequest.get.patent_rights = { 'page-size': 10000 }
          if(checked.operating.includes("award")) searchRequest.get.awards = { 'page-size': 10000 }
        }
        // 財務指標
        if (this.getterLimitG) {
          if(checked.finance.includes("news")) searchRequest.get.publics_news = { 'page-size': 10000 }
          if(checked.finance.includes("revenue")) searchRequest.get.revenue_history = { 'page-size': 10000 }
          if(checked.finance.includes("ifrss")) searchRequest.get.ifrss_summary = { 'page-size': 10000 }
        }
        // 取得資料
        let result = await apis.companyGet(searchRequest)
        if (result.success === false) {
          this.isActiveCompanyDetailDownloader = false
          return
        }

        this.downloadCompanyData = result.payload
        //篩出ignore
        if(this.downloadCompanyData.news) this.downloadCompanyData.news.list = filterIgnore(this.downloadCompanyData.news.list, ignore.news)
        if(this.downloadCompanyData.verdicts) this.downloadCompanyData.verdicts.list = filterIgnore(this.downloadCompanyData.verdicts.list, ignore.verdicts)

        this.isActiveCompanyDetailDownloader = true

        // 要等至pdf完成後才結束loading
        let pdfResult = await new Promise(resolve => {
          let check = () => {
            setTimeout(() => {
              if (this.isActiveCompanyDetailDownloader === false) {
                resolve(true)
              } else {
                check()
              }
            }, 1000)
          }
          check()
        })
        this.$refs.elFloatButton.finishPercent(pdfResult)
        return
      } catch (e) {
        console.error(e)
        return
      }


      function filterIgnore(list, ignore){
        let i = 0;
        while (i < list.length && ignore.length > 0) {
          let found = ignore.indexOf(list[i].uniID)
          if(found >= 0){
            list.splice(i,1)
            ignore.splice(found,1)
          }else{
            i++;
          }
        }

        return list
      }
    }
  },
  // mounted () {
  //   this.downloadAction()
  // },
  watch: {
    '$route.query': {
      async handler (d) {
        if (d.uniid) {
          // 查詢
          await this.actionRiskKeywordGroup()
          await this.actionCompanyDetail(d.uniid)
          this.isCompanyDataLoading = false
          this.actionCompanyNews(d.uniid)
          this.isCompanyNewsLoading = false          
        }
      },
      immediate: true
    },
    'currentTabName': {
      async handler (d) {
        // 切換到「關係人地圖」頁籤，並且資料未取得

        // -- @Q@ 舊版 --
        // if (d === 'stock' && this.companyStockList.length === 0) {
        //   this.isStockLoading = true
        //   getCompanyStockList(this.currentUniID)
        //     .then(companyStockList => {
        //       // @Q@ 星展特規 - 不顯示歇業公司
        //       this.companyStockList = companyStockList
        //         .filter(d => {
        //           return isCompanyClosed(d.basic.company_operate) === false
        //         })
        //         .map(d => {
        //           for (let i in d.stock.down) {
        //             if (isCompanyClosed(d.stock.down[i].company_operate)) {
        //               delete d.stock.down[i]
        //             }
        //           }
        //           for (let i in d.stock['down-big']) {
        //             if (isCompanyClosed(d.stock['down-big'][i].company_operate)) {
        //               delete d.stock['down-big'][i]
        //             }
        //           }
        //           for (let i in d.stock.top) {
        //             if (isCompanyClosed(d.stock.top[i].company_operate)) {
        //               delete d.stock.top[i]
        //             }
        //           }
        //           for (let i in d.stock['top-big']) {
        //             if (isCompanyClosed(d.stock['top-big'][i].company_operate)) {
        //               delete d.stock['top-big'][i]
        //             }
        //           }
        //           return d
        //         })

        //       this.isStockLoading = false
        //     }).catch(e => {
        //       console.log(e)
        //       this.isStockLoading = false
        //     })
          
        // }

        if (d === 'stock' && !this.companyGoFull.nodes.length) {
          this.actionCompanyGraphForDbs(this.currentUniID)
        }
      }
    }
  },
  created () {
    this.chartVersion = relationshipChartVersionStorage.getVersion()
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (!vm.$route.query.uniid) {
        routerService('replace', { name: 'home' })
      }
    })
  }
}
</script>

<style lang="scss">
.chart-container {
  margin-bottom: 20px;
}
</style>
